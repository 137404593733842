import './App.css';
import { MainHeader } from './components/common';

function App() {
  return (
    <div className="App">
      <MainHeader>NEI😭</MainHeader>
    </div>
  );
}

export default App;
